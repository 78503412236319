<template>
  <div>
    <el-form inline class="mb-20" label-width="80px">
          <el-form-item label="搜索">
            <el-input placeholder="请输入搜索内容" v-model="params.search"/>
          </el-form-item>

          <el-form-item>
              <el-button type="primary" @click="submit">搜索</el-button>
              <el-button @click="reset">重置</el-button>
          </el-form-item>
<!--      <el-form-item label="学号">-->
<!--        <el-input placeholder="请输入学号"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="姓名">-->
<!--        <el-input placeholder="请输入姓名"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="性别">-->
<!--        <el-select placeholder="请选择性别">-->
<!--          <el-option label="男"/>-->
<!--          <el-option label="女"/>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="专业">-->
<!--        <el-input placeholder="请输入专业"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="班级">-->
<!--        <el-input placeholder="请输入班级"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="微信号">-->
<!--        <el-input placeholder="请输入微信号"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="手机号">-->
<!--        <el-input placeholder="请输入手机号"/>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="账号状态">-->
<!--        <el-select placeholder="请选择账号状态">-->
<!--          <el-option label="正常"/>-->
<!--          <el-option label="封禁"/>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="使用情况">-->
<!--        <el-select placeholder="请选择使用情况">-->
<!--          <el-option label="当天"/>-->
<!--          <el-option label="7天内"/>-->
<!--          <el-option label="30天内"/>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="发帖情况">-->
<!--        <el-select placeholder="请选择发帖情况">-->
<!--          <el-option label="发过帖子"/>-->
<!--          <el-option label="没发过帖子"/>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <div>
      </div>
    </el-form>
    <el-table class="mb-20" stripe border :data="list">
<!--      <el-table-column align="center" prop="number" label="学号"/>-->
      <el-table-column align="center" prop="id" label="ID" />
      <el-table-column align="center" label="头像" >
        <template #default="{row, $index}">
          <el-avatar
            :src="row.avatarUrl"
          ></el-avatar>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="nickName" label="姓名" />
<!--      <el-table-column align="center" prop="gender.text" label="性别"/>-->
<!--      <el-table-column align="center" prop="major" label="专业"/>-->
<!--      <el-table-column align="center" prop="class" label="班级"/>-->
      <el-table-column align="center" prop="wechat" label="微信号"/>
      <el-table-column align="center" prop="phone" label="手机号"/>
      <el-table-column align="center" label="认证状态">
        <template #default="{row, $index}">
          {{!row.examine_status ? '未认证' : row.examine_status.text}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="status.text" label="账号状态"/>
      <el-table-column align="center" prop="status.text" label="接单状态">
        <template #default="{row, $index}">
          {{row.is_receiver && row.examine_status?.value == 10 ? '能' : '否'}}
        </template>
      </el-table-column>
<!--      <el-table-column align="center" prop="login" label="使用情况"/>-->
<!--      <el-table-column align="center" prop="topic" label="发帖情况"/>-->
      <el-table-column align="center" label="操作">
        <template #default="{row, $index}">
          <el-button type="text" @click="showDetailBox(row)">查看详情</el-button>

<!--          <el-button type="text" @click="openDialog(row)" v-if="row.status.value == 10">封禁</el-button>-->
<!--          <el-popconfirm title="是否确定解封?" confirmButtonText="确定" cancelButtonText="取消" @confirm="ban(row)" v-else-if="row.status.value == 20">-->
<!--            <template #reference>-->
<!--              <el-button type="text">解封</el-button>-->
<!--            </template>-->
<!--          </el-popconfirm>-->
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :page-sizes="params.per_page"
          :current-page="params.page"
          :page-size="10"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-model="detailBox"
      title="用户详情"
      width="700px"
      top="5vh"
    >
      <el-descriptions border :column="2">
        <el-descriptions-item label="头像" :span="2">
          <el-image
            style="width: 100px;height: 100px;"
            :src="detail.avatarUrl"
            :preview-src-list="[detail.avatarUrl]"
          />
        </el-descriptions-item>
<!--        <el-descriptions-item label="学号">123123123</el-descriptions-item>-->
        <el-descriptions-item label="姓名">{{detail.nickName}}</el-descriptions-item>
        <el-descriptions-item label="性别">{{detail.gender.text}}</el-descriptions-item>
<!--        <el-descriptions-item label="专业">计算机</el-descriptions-item>-->
<!--        <el-descriptions-item label="班级">2021-计算机-一班</el-descriptions-item>-->
        <el-descriptions-item label="微信号">{{detail.wechat}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{detail.phone}}</el-descriptions-item>
        <el-descriptions-item label="认证状态">
          <span style="margin-right: 8px">{{!detail.examine_status ? '未认证' : detail.examine_status.text}}</span>
          <el-button type="text" @click="openDialog(detail)" v-if="detail.status.value == 10">封禁</el-button>
          <el-popconfirm title="是否确定解封?" confirmButtonText="确定" cancelButtonText="取消" @confirm="ban(detail)" v-else-if="detail.status.value == 20">
            <template #reference>
              <el-button type="text">解封</el-button>
            </template>
          </el-popconfirm>
        </el-descriptions-item>
        <el-descriptions-item label="能否接跑腿单">
          <el-switch v-model="detail.is_receiver" inline-prompt active-text="能" inactive-text="否" @change="switchStatus">
          </el-switch>
        </el-descriptions-item>
<!--        <el-descriptions-item label="使用情况">当天登录</el-descriptions-item>-->
<!--        <el-descriptions-item label="发帖情况">没发过帖子</el-descriptions-item>-->
      </el-descriptions>
      <template #footer>
        <div>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogTableVisible" title="选择封禁时间">
      <el-form :form="form">
        <el-form-item label="封禁时间" :label-width="formLabelWidth">
          <el-select v-model="form.ban_day" placeholder="请选择封禁时间">
            <template v-for="(item, index) in ban_list" :key="inde">
              <el-option :label="item.text" :value="item.value"></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancal">取消</el-button>
        <el-button type="primary" @click="confirm"
        >确定</el-button
        >
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import {ElMessage} from "element-plus";

export default {
  name: 'User',
  data() {
    return {
      params: {
        page: 1,
        per_page: 10,
        search: '',
      },
      total: 1,
      list: [],
      detailBox: false,
      dialogTableVisible: false,
      form: {
        ban_day: ''
      },
      formLabelWidth: '120px',
      ban_list: [
        {
          text: '警告',
          value: 0
        },
        {
          text: '7天',
          value: 30
        },
        {
          text: '1个月',
          value: 30
        },
        {
          text: '3个月',
          value: 90
        },
        {
          text: '永久',
          value: 99999
        }
      ],
      flagData: {},
      detail: {
        gender: {},
        status: {},
      }
    };
  },
  created() {
    this.getList(1)
  },
  methods: {
    showDetailBox(data) {
      data.is_receiver = !!data.is_receiver;
      this.detail = data;
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.wxuser.page({
        page: page || this.params.page,
        search: this.params.search,
      }).then((res) => {
        // console.log(res);
        that.list = res.data.data;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    submit() {
      this.getList(1)
    },
    reset() {
      this.params.search = '';
      this.submit()
    },
    changePage(page) {
      this.getList(page)
    },
    switchDialog() {
      this.dialogTableVisible = !this.dialogTableVisible
    },
    openDialog(data) {
      this.flagData = Object.assign({} ,data)
      this.switchDialog()
    },
    cancal() {
      this.form = {
        ban_day: '',
      }
      this.flagData = {};
      this.switchDialog()
    },
    confirm() {
      this.$api.wxuser.edit({
        id: this.flagData.id,
        status: 20,
        ban_day: this.form.ban_day,
      }).then(res => {

        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        this.getList()
        this.cancal()
      })
    },
    switchStatus() {
      let data = {};
      data.id = this.detail.id;
      data.is_receiver = this.detail.is_receiver ? 1 : 0;
      this.$api.wxuser.receiver(data).then(res => {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        this.getList()
      })
    },
    ban(data) {
      let status = 0;
      if(data.status.value == 10) {
        status = 20
      } else if(data.status.value == 20) {
        status = 10
      }
      this.$api.wxuser.edit({
        id: data.id,
        status,
      }).then(res => {

        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        this.getList()
      })
    }
  },
};
</script>
